import './src/styles/global.css';
import ReactDOM from "react-dom/client";

export const onRouteUpdate = ({ location, prevLocation }) => {
  const currentPath = location.href; // Get current full URL
  const previousPath = prevLocation ? prevLocation.href : null; // Get previous full URL

  if (previousPath) {
    window.posthog.capture('scroll_depth', {
      'last scroll percentage': Math.min(1, (window.innerHeight + window.pageYOffset) / document.body.scrollHeight).toFixed(2),
      'fullURL': previousPath, // Capture previous full URL
    });
  } else {
    window.posthog.capture('scroll_depth', {
      'last scroll percentage': Math.min(1, (window.innerHeight + window.pageYOffset) / document.body.scrollHeight).toFixed(2),
      'fullURL': currentPath, // Capture current full URL
    });
  }
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
